import React from 'react'
import '../App.css';


export default function Footer() {
  return (
    <div>
        <footer className="footertext">
        &copy; 2023 Art Gallery. All rights reserved.
      </footer>
    </div>
  )
}
