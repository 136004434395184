// import logo from './logo.svg';
import "./App.css";
import Contact from "./Compnents/Contact";
import Crousel from "./Compnents/Crousel";
import Footer from "./Compnents/Footer";
import Gallery from "./Compnents/Gallery";
import Home from "./Compnents/Home";

function App() {
  
  return (
    <div className="App">
      <Home />
      <Crousel/>
      <section id="home">
      <div class="container my-3">
      <p class="text-justify">
                At Mavis Wonder we champion the power of art and recognize it’s historically been a vehicle to bring attention to social issues. So we offer something different than the typical t-shirts available on the market. Our one of a kind artwork, inspired by all aspects of life, provide wearers an opportunity to express themselves and highlight issues in a thought provoking way.
        <br></br>
        Mavis Wonder incorporates original artwork on lightweight shirts made from high quality combed ring spun cotton. The tee’s tapered cut gives you a comfortable fit without being too tight or too loose.
        <br></br>
Art is a powerful force for personal expression and fashion artistically communicate who we are. It’s the Art of Fashion, by Mavis Wonder.
        </p>
        </div>
      </section>
      <h2 className="sectionName"  id="gallery">The Gallery</h2>

      <Gallery />
      <Contact />
      <Footer/>
    
    </div>
  );
}

export default App;
