import React from "react";

export default function Crousel() {
  return (
    <div className="container my-3 ">
      <div
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="4" aria-label="Slide 5"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="5" aria-label="Slide 6"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="6" aria-label="Slide 7"></button>
    <button type="button" data-bs-target="#carouselExampleControls" data-bs-slide-to="7" aria-label="Slide 8"></button>
  </div>
        <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="Slide (1).jpeg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="Slide (2).jpeg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="Slide (3).jpeg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="Slide (4).jpeg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="Slide (5).png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="Slide (6).jpeg" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="Slide (7).png" class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src="slideshowlogo.png" class="d-block w-100" alt="..." />
          </div>

        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next "
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
