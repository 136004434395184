import React from 'react'

export default function GalleryImage(props) {
  return (
    <div>
      <div className="cen">
        <section className="slideshow">
        <h5 className="imageName">{props.name}</h5>

        <div className="image-section">
          {/* <img src= alt="Artwork 1" /> */}
          <img src={props.imagesrc} class="img-fluid" alt="artworks"/>

        </div>
      </section>
      </div>
      <section className="artwork-description">
        <p className="container my-3">  
          {props.desc}
        </p>
      </section>

      {/* <div class="d-flex justify-content-center m-3"> */}
      <div class="container d-flex justify-content-center align-items-center mb-5">

      <div className="model-section">
          {/* <img src= alt="Artwork 1" /> */}
          <img src={props.model} class="img-fluid" alt="artworks"/>

        </div>
        <a
          class="btn skin-color m-3"
          href="https://www.etsy.com/shop/MavisWonder"
          target="_blank"
          rel="noreferrer"
          role="button"
        >
          Buy Now On Etsy
        </a>
      </div>
    </div>
  )
}
