import React from "react";
import "../App.css";
import GalleryImage from "./GalleryImage";

let images=[{name:"A Moment of Truth",imagesrc:"/image4.jpg",
desc:`At first glance, A Moment Of Truth appears to be a vibrant picture of two close friends. But a deeper look reveals a depiction of the complicated dynamic of female relationships. The closeness of their bodies indicates intimacy but the distance between their heads suggests they’re of different mindsets. The colors blue and green represents loyalty and envy respectively. But can a friend be both, as the harmonious blue and green background suggest? Sisterhood doesn’t negate basic competitive human nature. The artwork highlights how jealousy can exist even between two people who appear to be supportive of one another.`,
model:"/wearing (1).jpeg"},
{name:"Every Woman",imagesrc:"/image3.jpg",
desc:`Every Woman captures the strength of a woman while also portraying her vulnerability and grace. The vibrant colors exude the confidence of a woman who knows her power, while the layered brush strokes illuminate the complexity of a her journey. It’s an inspiring piece of art that celebrates embracing one’s true self, both the desirable parts and the not so favorable.`,
model:"/wearing (2).png"},
{name:"Mind At Peace",imagesrc:"/image2.jpg",
desc:`Inspired by the need to cope with anxiety and fatigue in a post-pandemic world, Mind At Peace depicts meditation as an avenue for promoting good mental health. The semi abstract visual impressions engage the viewer’s emotions, while the artwork’s use of muted shades of blue and purple instantly convey a sense of calmness. The piece illustrates the importance of maintaining one's calmness and the significance of taking time to improve your mental health.`,
model:"/wearing (3).png"},
{name:"Xpect Resistance",imagesrc:"/image1.jpg",
desc:`Xpect Resistance pays homage to Malcolm X’s iconic photo, By Any Means Necessary. Like it’s inspiration, the artwork makes a powerful statement about resistance and strength in the face of oppression. The picture of a dove with an olive branch hints to a desire for a peaceful resolution, while the wearing of a bullet proof vest shows preparation for a fight. The woman’s gaze out of the window adds a layer of complexity and intrigue to the piece. Is she being sought or seeking an opportunity? Xpect Resistance is a reminder of the need for action to obtain justice and peace.`,
model:"/wearing (4).png"},
{name:"The Cover Girl",imagesrc:"/image5.jpg",
desc:`The Cover Girl is a Wabi-Sabi inspired art piece that challenges society's definition of beauty. The artwork showcases the mesmerizing gaze of a carefree woman. The soft colors and gentle lighting presents the subject in such a way that demands the viewer to explore the notion of beauty versus perfection. If beauty simply lay in the eye of beholders then there is no absolute standard of beauty. Therefore, no one is ever really more beautiful, or uglier, than anyone else.`,
model:"/wearing (5).png"},]

export default function Gallery() {
  return (
    <div>
      {images.map((image, index) => (
        <GalleryImage key={index} name={image.name} imagesrc={image.imagesrc} desc={image.desc} model={image.model}/>
      ))}

      {/* <GalleryImage imagesrc={"/image2.jpg"} desc={`We aim to showcase diverse and extraordinary artworks from talented
          artists around the world."Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis a`}/> */}
    </div>
  );
}
