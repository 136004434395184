import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import '../App.css';

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_2jxhte8",
        "template_nr0v812",
        {
          voice: form.name,
          to_name: "Fedagnt",
          from_email: form.email,
          to_email: "contact@mail.com",
          message: form.message,
        },"HwEo-aEWqfhIyNz_q"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setForm({
            name: "",
            email: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);

          console.log(error);
          alert("Something went wrong.");
        }
      );
  };

  return (
    <div id="contactus" className=" centered-div"
    >
        <h3 className="sectionName">Get in touch</h3>

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="centered-elements"
        >
          <label >
            <span >Email</span>
            <input className="textFeild"
              type="email"
              name="email"
              value={form.email}
              onChange={handleChange}
            />
          </label>
          <hr></hr>
          <label >
            <span >Phone Number</span>
            <input className="textFeild"
              type="text"
              name="name"
              value={form.name}
              onChange={handleChange}
            />
          </label>
          <hr></hr>
          <label >
            <span >Message</span>
            <textarea
              rows={7}
              name="message"
              value={form.message}
              onChange={handleChange}
              placeholder="What do you want to say?"
            />
          </label>
          <div className="btncenter">
          <button
            type="submit"
          >
            {loading ? "Sending..." : "Send"}
          </button>
</div>
        </form>

    </div>
  );
};

export default Contact;